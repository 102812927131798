import React from "react"

import styles from "./map.module.css"

function GoogleMap() {
  return (
    <div className={styles.mapouter}>
      <div className={styles.gmap_canvas}>
        <iframe
          title="map"
          width="100%"
          height="100%"
          id="gmap_canvas"
          src="https://maps.google.com/maps?q=Wupertaslka%203&t=&z=13&ie=UTF8&iwloc=&output=embed"
          scrolling="no"
        />
      </div>
    </div>
  )
}

export default GoogleMap
