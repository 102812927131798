import React from "react"
import { Link } from "gatsby"
import { Navbar, Nav, Image } from "react-bootstrap"
import { useTranslation } from "react-i18next"

import styles from "./navigation.module.css"
import brand from "./brand.png"

const I18N_NAV = "header.navigation"

export default function Navigation() {
  const { t } = useTranslation()

  return (
    <Navbar className={styles.navbar}>
      <Navbar.Brand>
        <Link to="#">
          <Image src={brand} fluid />
        </Link>
      </Navbar.Brand>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link href="/" className={styles.navlink}>
            <strong>{t(`${I18N_NAV}.about`)}</strong>
          </Nav.Link>

          <Nav.Link href="/galeria" className={styles.navlink}>
            <strong>{t(`${I18N_NAV}.gallery`)}</strong>
          </Nav.Link>

          <Nav.Link href="#contact" className={styles.navlink}>
            <strong>{t(`${I18N_NAV}.contact`)}</strong>
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}
