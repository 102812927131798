import React from "react"
import { Row, Col } from "react-bootstrap"
import { useTranslation } from "react-i18next"

import styles from "./footer.module.css"

const I18N_MARK = "footer.mark"
const I18N_CONTACT = "footer.contact"

export default function FooterInfo() {
  const { t } = useTranslation()

  return (
    <Row>
      <Col className={styles.contact}>
        <p>
          <strong>{t(`${I18N_MARK}.title`)}</strong>{" "}
        </p>
        <p>{t(`${I18N_MARK}.street`)}</p>
        <p>{t(`${I18N_MARK}.city`)}</p>
        <p>{t(`${I18N_MARK}.ico`)}</p>
      </Col>
      <Col className={styles.contact}>
        <p>
          <strong>{t(`${I18N_CONTACT}.title`)}</strong>
        </p>
        <p>{t(`${I18N_CONTACT}.name`)}</p>
        <p>{t(`${I18N_CONTACT}.phone`)}</p>
        <p>{t(`${I18N_CONTACT}.mail`)}</p>
      </Col>
    </Row>
  )
}
