import React, { useState } from "react"
import { Link } from "gatsby"
import { Image } from "react-bootstrap"
import { FaBars } from "react-icons/fa"
import classNames from "classnames"
import { useTranslation } from "react-i18next"

import styles from "./mobileNav.module.css"
import logo from "./brand.png"

const I18N_NAV = "header.navigation"

export default function MobileNavigation() {
  const { t } = useTranslation()
  const [isVisible, setVisisble] = useState(false)

  function toggleMenu() {
    setVisisble(!isVisible)
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.logoContainer}>
          <Link to="#">
            <Image src={logo} className={styles.logo} />
          </Link>
        </div>
        <FaBars color="white" className={styles.icon} onClick={toggleMenu} />
      </div>
      <div
        className={classNames(
          styles.nav,
          isVisible ? styles.visible : styles.hidden
        )}
      >
        <div onClick={toggleMenu} className={styles.navlink}>
          <Link to="/">
            <strong>{t(`${I18N_NAV}.about`)}</strong>
          </Link>
        </div>
        <div onClick={toggleMenu} className={styles.navlink}>
          <Link to="/galeria">
            <strong>{t(`${I18N_NAV}.gallery`)}</strong>
          </Link>
        </div>
        <div className={styles.navlink} onClick={toggleMenu}>
          <Link to="#contact">
            <strong>{t(`${I18N_NAV}.contact`)}</strong>
          </Link>
        </div>
      </div>
    </>
  )
}
