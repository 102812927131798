import React from "react"
import { Container, Row, Col } from "react-bootstrap"

import GoogleMap from "./map/GoogleMap"
import FooterInfo from "./FooterInfo"
import styles from "./footer.module.css"

export default function Footer() {
  return (
    <footer className={styles.footer}>
      <Container name="contact">
        <Row className={styles.row}>
          <Col md={7}>
            <FooterInfo />
          </Col>
          <Col md={5}>
            <GoogleMap />
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
