import React from "react"
import PropTypes from "prop-types"
import { withTrans } from "../i18n/withTrans"
import Header from "./header"
import Footer from "./footer"

function Layout({ children }) {
  return (
    <div style={{ display: "flex", flexFlow: "column", height: "100%" }}>
      <div style={{ flex: "0 1 auto" }}>
        <Header />
      </div>
      <div style={{ flex: "1 1 auto" }}>{children}</div>
      {/* <div> */}
      <Footer />
      {/* </div> */}
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default withTrans(Layout)
