import React from "react"

import Navigation from "./navbar/Navigation"
import MobileNavigation from "./navbar/MobileNavigation"

export default function Header() {
  return (
    <>
      <Navigation />
      <MobileNavigation />
    </>
  )
}
